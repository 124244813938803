import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import readXlsxFile from "read-excel-file";
import { Grid, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ReactGA from "react-ga";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  activeLink: {
    position: "relative",
    "&:after": {
      position: "absolute",
      content: "''",
      top: "100%",
      left: "25%",
      width: "50%",
      height: "5px",
      borderRadius: "5px",
      backgroundColor: "white",
    },
    "&:focused": {
      otuline: "none",
    },
  },
}));

const email = new URLSearchParams(window.location.search).get("email");

const device_header = [
  { text: "Client", value: "client" },
  { text: "City", value: "city" },
  { text: "Device Transaction", value: "transaction" },
  { text: "Date and Time", value: "date" },
  { text: "Added by", value: "added_by" },
  { text: "Vendor", value: "vendor" },
  { text: "Hardware Type", value: "hardware_type" },
  { text: "Dashboard Type", value: "dashboard_type" },
  { text: "Hardware Model", value: "hardware_model" },
  { text: "Parsed By", value: "parsed_by" },
  { text: "Hardware No.", value: "hardware_no" },
  { text: "Hardware Serial No.", value: "hardware_serial_no" },
  { text: "Hardware IMEI", value: "hardware_imei" },
  { text: "Hardware Status", value: "hardware_status" },
  { text: "Hardware Position", value: "hardware_position" },
  { text: "Hardware Remarks", value: "hardware_remarks" },
  { text: "Sim Type", value: "sim_type" },
  { text: "Sim Mobile No.", value: "sim_mobile_no" },
  { text: "Sim Serial No.", value: "sim_serial_no" },
  { text: "Sim Code", value: "sim_code" },
  { text: "Sim Status", value: "sim_status" },
  { text: "Sim Position", value: "sim_position" },
  { text: "Sim Remarks", value: "sim_remarks" },
  { text: "BT App Password", value: "bt_app_pwd" },
  { text: "Ownership", value: "device_ownership" },
  { text: "Intracity Device Type", value: "intracity_device_type" },
];

// const sim_header = [
//   { text: "Sim Transaction", value: "transaction" },
//   { text: "Date and Time", value: "date" },
//   { text: "Added By", value: "added_by" },
//   { text: "Vendor", value: "vendor" },
//   { text: "Operator", value: "operator" },
//   { text: "Sim Type", value: "sim_type" },
//   { text: "Sim Mobile No.", value: "sim_mobile_no" },
//   { text: "Sim Serial No.", value: "sim_serial_no" },
//   { text: "Sim Code", value: "sim_code" },
//   { text: "Sim Status on Portal", value: "sim_status" },
//   { text: "Inventory", value: "inventory" },
// ];
const sim_header = [
  { text: "Mobile Number", value: "sim_mobile_no" },
  { text: "Sim Number", value: "sim_serial_no" },
  { text: "Operator", value: "operator" },
  { text: "Type", value: "sim_type" },
 { text: "Sim Status", value: "sim_status" }
];

export default function Navbar(props) {
  const blankDevice = {
    client: "",
    vendor: "",
    city: "",
    transaction: "",
    date: "",
    added_by: "",
    hardware_type: "",
    hardware_model: "",
    parsed_by: "",
    hardware_no: "",
    hardware_serial_no: "",
    hardware_imei: "",
    hardware_status: "",
    hardware_position: "",
    hardware_remarks: "",
    sim_type: "",
    sim_serial_no: "",
    sim_mobile_no: "",
    sim_code: "",
    sim_status: "",
    sim_position: "",
    sim_remarks: "",
  };

  const blankSim = {
    transaction: "",
    date: "",
    added_by: "",
    vendor: "",
    operator: "",
    sim_type: "",
    sim_mobile_no: "",
    sim_serial_no: "",
    sim_code: "",
    sim_status: "",
    inventory: "",
  };

  const [state, setState] = React.useState({
    file: null,
    fileData: null,
    newDevice: {
      ...blankDevice,
    },
    newSim: {
      ...blankSim,
    },
  });
  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (e, key, prop) => {
    let value = e.target.value;
    if (key) {
      setState((state) => ({
        ...state,
        [key]: {
          ...state[key],
          [prop]: value,
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        [prop]: value,
      }));
    }
  };
  //

  const classes = useStyles();
  const history = useHistory();
  const user = history.location.search.split("user=")[1]
    ? history.location.search.split("user=")[1].split("&")[0]
    : "Guest";
  console.log(user);
  const onFileChange = (e, mode) => {
    let file = e.target.files[0];
    const deviceSchema = {
      Client: {
        prop: "client",
        type: String,
      },
      _id: {
        prop: "_id",
        type: String,
      },
      City: {
        prop: "city",
        type: String,
      },
      "Device Transaction": {
        prop: "transaction",
        type: String,
      },
      "Date and Time": {
        prop: "date",
        type: Date,
      },
      "Added by": {
        prop: "added_by",
        type: String,
      },
      "Hardware Vendor": {
        prop: "vendor",
        type: String,
      },
      "Hardware Type": {
        prop: "hardware_type",
        type: String,
      },
      "Dashboard Type": {
        prop: "dashboard_type",
        type: String,
      },
      "Hardware Model": {
        prop: "hardware_model",
        type: String,
      },
      "Parsed By": {
        prop: "parsed_by",
        type: String,
      },
      "Hardware No.": {
        prop: "hardware_no",
        type: String,
      },
      "Hardware Serial No.": {
        prop: "hardware_serial_no",
        type: String,
      },
      "Hardware IMEI": {
        prop: "hardware_imei",
        type: String,
      },
      "Hardware Status": {
        prop: "hardware_status",
        type: String,
      },
      "Hardware Position": {
        prop: "hardware_position",
        type: String,
      },
      "Hardware Remarks": {
        prop: "hardware_remarks",
        type: String,
      },
      "Sim Type": {
        prop: "sim_type",
        type: String,
      },
      "Sim Mobile No.": {
        prop: "sim_mobile_no",
        type: String,
      },
      "Sim Serial No.": {
        prop: "sim_serial_no",
        type: String,
      },
      "Sim Code": {
        prop: "sim_code",
        type: String,
      },
      "Sim Status": {
        prop: "sim_status",
        type: String,
      },
      "Sim Position": {
        prop: "sim_position",
        type: String,
      },
      "Sim Remarks": {
        prop: "sim_remarks",
        type: String,
      },
      "BT App Password": {
        prop: "bt_app_pwd",
        type: String,
      },
      "Ownership": {
        prop: "device_ownership",
        type: String,
      },
      "Intracity Device Type": {
        prop: "intracity_device_type",
        type: String,
      },
      
    };
    const simSchema = {
      _id: {
        prop: "_id",
        type: String,
      },
      "Operator": {
        prop: "operator",
        type: String,
      },
      "Type": {
        prop: "sim_type",
        type: String,
      },
      "Mobile Number": {
        prop: "sim_mobile_no",
        type: String,
      },
      "Sim Number": {
        prop: "sim_serial_no",
        type: String,
      },
      "Sim Status": {
        prop: "sim_status",
        type: String,
      }
    };
    readXlsxFile(file, {
      schema: mode === "devices" ? deviceSchema : simSchema,
    }).then((rows) => {
      let fileData = rows.rows?.map((item) => ({
        ...item,
        submitted_by: email || null,
      }));
      // console.log("file uploaded data", fileData , rows);
      setState((state) => ({
        ...state,
        file,
        fileData,
      }));
    });
  };
  const subimtWiredHandler = ()=>{
    if (state.fileData && state?.fileData?.length ) {
      let url = "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/add-wired-devices?email="+email
      // console.log("editAll",editAll)
      const temp_data = state?.fileData 
    
      axios
        .post(
          url,
          // JSON.stringify(state.fileData)
          { data: temp_data }
        )
        .then((res) => {
          console.log(res);
        
         
          ReactGA.event({
            category: "Wirded Devices uploaded",
            action: `${user} "added" ${
              temp_data.length
            } devices at ${new Date()}`,
          });
          if (res.status === 200) {
            let msg =  "device added";
            alert(msg);
            props.reloadNow((v) => !v);
          }
          
        })
        .catch((err) => {
          let error_msg = err.response?.data?.msg ?? "Error Occured, check console for more"
          let actual_error = ""
          if(err?.response?.data?.result){
            
            if(err?.response?.data?.result?.intracity){
              actual_error += "\n ***** Intracity Devices Error *****"
              if(!err?.response?.data?.result?.intracity?.client && err?.response?.data?.result?.intracity?.client_list?.length)
              actual_error += "\nInvalid Client Name - "+ err?.response?.data?.result?.intracity?.client_list.toString()
              if(!err?.response?.data?.result?.intracity?.facility && err?.response?.data?.result?.intracity?.facility_list?.length)
              actual_error += "\nInvalid Facility Name - "+ err?.response?.data?.result?.intracity?.facility_list.toString()
            }
          }
          alert(error_msg+actual_error);
          console.log(err?.response);
        });
    }

  };
  const subimtHandler = (editAll,edit_devices=[]) => {
    if ((state.fileData && state?.fileData?.length ) || (edit_devices?.length)) {
      let url = "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/add-new-devices?email="+email
      // console.log("editAll",editAll)
      const temp_data = state?.fileData ?? edit_devices
      if(editAll === true)
      url += "&editAll=true"
      axios
        .post(
          url,
          // JSON.stringify(state.fileData)
          { data: temp_data }
        )
        .then((res) => {
          console.log(res);
        
          if(edit_devices?.length)
          ReactGA.event({
            category: "Devices edited",
            action: `${user} edited ${
              temp_data.length
            } devices at ${new Date()}`,
          });
          else
          ReactGA.event({
            category: "Devices uploaded",
            action: `${user} "added" ${
              temp_data.length
            } devices at ${new Date()}`,
          });
          if (res.status === 200) {
            let msg = edit_devices?.length ? "device updated" : "device added";
            alert(msg);
            props.reloadNow((v) => !v);
          }
        })
        .catch((err) => {
          let error_msg = err.response?.data?.msg ?? "Error Occured, check console for more"
          let actual_error = ""
          if(err?.response?.data?.result){
            if(err?.response?.data?.result?.sct){
              actual_error = "\n ***** SCT Devices Error *****"
              if(!err?.response?.data?.result?.sct?.client)
              actual_error += "\nInvalid Client Name - "+ err?.response?.data?.result?.sct?.client_list.toString()
              if(!err?.response?.data?.result?.sct?.facility)
              actual_error += "\nInvalid Facility Name - "+ err?.response?.data?.result?.sct?.facility_list.toString()
            }
            if(err?.response?.data?.result?.intracity){
              actual_error += "\n ***** Intracity Devices Error *****"
              if(!err?.response?.data?.result?.intracity?.client && err?.response?.data?.result?.intracity?.client_list?.length)
              actual_error += "\nInvalid Client Name - "+ err?.response?.data?.result?.intracity?.client_list.toString()
              if(!err?.response?.data?.result?.intracity?.facility && err?.response?.data?.result?.intracity?.facility_list?.length)
              actual_error += "\nInvalid Facility Name - "+ err?.response?.data?.result?.intracity?.facility_list.toString()
            }
          }
          alert(error_msg+actual_error);
          console.log(err?.response);
        });
    }
  };

  const subimtEditHandler = () => {
    if (state.fileData && state.fileData.length > 0) {
      if (!state.fileData.every((data) => data["_id"] && data["_id"].trim())) {
        alert("Cannot edit without _id");
        return;
      }

      subimtHandler(true,state.fileData)
      // axios
      //   .post(
      //     "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/edit-devices",
      //     JSON.stringify(state.fileData)
      //   )
      //   .then((res) => {
      //     console.log(res);
      //     ReactGA.event({
      //       category: "Devices edited",
      //       action: `${user} edited ${
      //         state.fileData.length
      //       } devices at ${new Date()}`,
      //     });
      //     if (res.status === 200) {
      //       alert(res.data.status);
      //       props.reloadNow((v) => !v);
      //     }
      //   })
      //   .catch((err) => {
      //     alert("Error Occured, check console for more");
      //     console.log(err);
      //   });
    }
  };

  const subimtSimHandler = () => {
    if (state.fileData && state.fileData.length > 0) {
      axios
        .post(
          "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/add-simcards?email="+email,
          // JSON.stringify(state.fileData)
          state.fileData
        )
        .then((res) => {
          console.log(res);
          ReactGA.event({
            category: "Sim cards uploaded",
            action: `${user} added ${
              state.fileData.length
            } sim cards at ${new Date()}`,
          });
          if (res.status === 200) {
            alert("Sim Cards Added");
            props.reloadNow((v) => !v);
          }
        })
        .catch((err) => {
          let message = err?.response?.data?.result?.message ?? "Error Occured, check console for more"
          if(err?.response?.data?.result?.result?.length)
          message += "\n"+err.response.data.result.result.toString()
          alert(message);
          console.log(err.response);
        });
    }
  };

  const subimtEditSimHandler = () => {
    if (state.fileData && state.fileData.length > 0) {
      axios
        .post(
          "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/edit-simcards",
          // JSON.stringify(state.fileData)
          state.fileData
        )
        .then((res) => {
          console.log(res);
          ReactGA.event({
            category: "Sim cards edited",
            action: `${user} edited ${
              state.fileData.length
            } sim cards at ${new Date()}`,
          });
          if (res.status === 200) {
            alert(res.data.status);
            props.reloadNow((v) => !v);
          }
        })
        .catch((err) => {
          alert("Error Occured, check console for more");
          console.log(err);
        });
    }
  };

  const addNewDevice = () => {
    let resp = window.confirm("Are you sure?");
    if (resp) {
      // console.log(JSON.stringify([state.newDevice]));
      axios
        .post(
          "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/add-new-devices",
          // JSON.stringify({ data: [state.newDevice] })
          { data: [{ ...state.newDevice, submitted_by: email || null }] }
        )
        .then((res) => {
          console.log(res);
          ReactGA.event({
            category: "Devices uploaded",
            action: `${user} added 1 devices at ${new Date()}`,
          });
          if (res.status === 200) {
            alert("device added");
            props.reloadNow((v) => !v);
          }
        })
        .catch((err) => {
          alert("Error Occured, check console for more");
          console.log(err);
        });
    }
  };

  const addNewSim = () => {
    let resp = window.confirm("Are you sure?");
    if (resp) {
      axios
        .post(
          "https://zjpdi2yojd.execute-api.ap-south-1.amazonaws.com/Prod/add-simcards",
          JSON.stringify([{ ...state.newSim, submitted_by: email || null }])
        )
        .then((res) => {
          console.log(res);
          ReactGA.event({
            category: "Sim cards uploaded",
            action: `${user} added 1 sim cards at ${new Date()}`,
          });
          if (res.status === 200) {
            alert("Sim Cards Added");
            props.reloadNow((v) => !v);
          }
        })
        .catch((err) => {
          alert("Error Occured, check console for more");
          console.log(err);
        });
    }
  };

  
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Dashboard:
            <Link to={{ pathname: "/" }} style={{ textDecoration: "none" }}>
              <Button
                style={{ color: "white" }}
                className={
                  history.location.pathname === "/" ? classes.activeLink : null
                }
              >
                Device
              </Button>
            </Link>
            <Link to={{ pathname: "/sim" }} style={{ textDecoration: "none" }}>
              <Button
                style={{ color: "white" }}
                className={
                  history.location.pathname === "/sim"
                    ? classes.activeLink
                    : null
                }
              >
                Sim
              </Button>
            </Link>
            <Link
              to={{ pathname: "/history" }}
              style={{ textDecoration: "none" }}
            >
              <Button
                style={{ color: "white" }}
                className={
                  history.location.pathname === "/history"
                    ? classes.activeLink
                    : null
                }
              >
                History
              </Button>
            </Link>
          </Typography>
          <div style={{ backgroundColor: "white" }}>
            <TextField
              variant="outlined"
              placeholder="Search"
              style={{ height: "50px" }}
              onChange={props.onSearch}
            />
          </div>
          <Button
            style={{ color: "white" }}
            data-toggle="modal"
            data-target="#exampleModal"
          >
            Add Devices
          </Button>
          <Button
            style={{ color: "white" }}
            data-toggle="modal"
            data-target="#exampleModal2"
          >
            Add Sim cards
          </Button>
          <Button style={{ color: "white" }} onClick={handleClickOpen}>
            Create New Device/Sim
          </Button>
        </Toolbar>
      </AppBar>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Devices
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Grid container>
                <Grid item xs={12}>
                  <div className="htmlForm-group">
                    <label htmlFor="">Use Excel Sheet</label>
                    <input
                      type="file"
                      onChange={(e) => onFileChange(e, "devices")}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />
                  </div>
                </Grid>
                 <Grid item xs={12}>
                  <TableContainer component={Paper} style={{ height: "600px" }}>
                    <Table
                      className={classes.table}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No.</TableCell>
                          {device_header.map((header) => (
                            <TableCell key={header.text} align="center">
                              {header.text}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(state.fileData || []).map((device, index) => {
                          return (
                            <TableRow hover key={index}>
                              <TableCell align="center">{index + 1}</TableCell>
                              {device_header.map((header) => (
                                <TableCell key={header.text} align="center">
                                  {device[header.value]
                                    ? device[header.value].toString()
                                    : "NA"}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <div className="htmlForm-group">
                <a
                  href="https://docs.google.com/spreadsheets/d/1iTZtxrHrErAfwOG2QUy18mN1QbL_uukUKAmIXhCPJRE/edit?ts=5ef456e5#gid=0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Format
                </a>
              </div>
              {/* <div className="htmlForm-group">
                <a href="/format.xlsx" download="format.xlsx">Download Format</a>
              </div> */}
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={subimtHandler}
              >
                Add & Edit
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=> subimtHandler(true)}
              >
                Edit All
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={()=> subimtWiredHandler()}
              >
                Add Wired Devices
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Sim Cards
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Grid container>
                <Grid item xs={12}>
                  <div className="htmlForm-group">
                    <label htmlFor="">Use Excel Sheet</label>
                    <input
                      type="file"
                      onChange={(e) => onFileChange(e, "sim")}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <TableContainer component={Paper} style={{ height: "600px" }}>
                    <Table
                      className={classes.table}
                      stickyHeader
                      aria-label="sticky table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No.</TableCell>
                          {sim_header.map((header) => (
                            <TableCell key={header.text} align="center">
                              {header.text}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(state.fileData || []).map((device, index) => {
                          return (
                            <TableRow hover key={index}>
                              <TableCell align="center">{index + 1}</TableCell>
                              {sim_header.map((header) => (
                                <TableCell key={header.text} align="center">
                                  {device[header.value]
                                    ? device[header.value].toString()
                                    : "NA"}
                                </TableCell>
                              ))}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
            <div className="modal-footer">
              <div className="htmlForm-group">
                <a
                  // href="https://docs.google.com/spreadsheets/u/2/d/1tJ7rNPqHNKEHdMEF2zcKJZHA2jKipqk-J6DtTT4jvWY/edit?ts=5ef456f9#gid=0"
                  href="https://assetsstatic.s3.ap-south-1.amazonaws.com/New+Sim+Upload+Format.xlsx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Format
                </a>
              </div>
              {/* <div className="htmlForm-group">
                <a href="/SimFormat.xlsx" download="format.xlsx">Download Format</a>
              </div> */}
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={subimtSimHandler}
              >
                Add
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={subimtEditSimHandler}
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={6} style={{ borderRight: "2px solid grey" }}>
            <DialogTitle id="form-dialog-title">Add Device</DialogTitle>
            <DialogContent>
              <Grid container justify="space-evenly">
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Client"
                    value={state.newDevice.client}
                    onChange={(e) => onChange(e, "newDevice", "client")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="City"
                    value={state.newDevice.city}
                    onChange={(e) => onChange(e, "newDevice", "city")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Transaction"
                    value={state.newDevice.transaction}
                    onChange={(e) => onChange(e, "newDevice", "transaction")}
                    select
                  >
                    <MenuItem value="">Select Transaction</MenuItem>
                    {[
                      ...new Set([
                        "Received From Vendor",
                        "Sent to Client",
                        "Received From Client",
                        state.newDevice.transaction,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Date"
                    value={state.newDevice.date}
                    onChange={(e) => onChange(e, "newDevice", "date")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Added by"
                    value={state.newDevice.added_by}
                    onChange={(e) => onChange(e, "newDevice", "added_by")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Vendor"
                    value={state.newDevice.vendor}
                    onChange={(e) => onChange(e, "newDevice", "vendor")}
                    select
                  >
                    <MenuItem value="">Select Vendor</MenuItem>
                    {[
                      ...new Set([
                        "Concox","I-triangle","Atlanta","Teltonika","Intugine","Spectrum","Other","Lumax","WanWay","IMZ",
                        state.newDevice.vendor,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Type"
                    value={state.newDevice.hardware_type}
                    onChange={(e) => onChange(e, "newDevice", "hardware_type")}
                    select
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    {[
                      ...new Set([
                        "GPS Device",
                        "GSM Device",
                        "Use and Throw",
                        "OBD",
                        "Other",
                        "GPS Lock",
                        "GPS Device 6k",
                        "GPS Device 10k",
                        state.newDevice.hardware_type,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* // */}
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Dashboard Type"
                    value={state.newDevice.dashboard_type}
                    onChange={(e) => onChange(e, "newDevice", "dashboard_type")}
                    select
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    {[
                      ...new Set([
                        "SCT",
                        "Intracity",
                        state.newDevice.dashboard_type,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/*  */}
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Model"
                    value={state.newDevice.hardware_model}
                    onChange={(e) => onChange(e, "newDevice", "hardware_model")}
                    select
                  >
                    <MenuItem value="">Select Model</MenuItem>
                    {[
                      ...new Set([
                        "AT4","Spectrum","TS101","Other","Intugine","G-300","S20","TrackLock 700","RNB","G-310P","G-310N","G-310","TT808","UNT",
                        state.newDevice.hardware_model,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Parsed By"
                    value={state.newDevice.parsed_by}
                    onChange={(e) => onChange(e, "newDevice", "parsed_by")}
                    select
                  >
                    <MenuItem value="">Select Parsed By</MenuItem>
                    {[
                      ...new Set([
                        "Intugine",
                        "Zeliot",
                        "Lumax",
                        state.newDevice.parsed_by,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Hardware No."
                    required
                    value={state.newDevice.hardware_no}
                    onChange={(e) => onChange(e, "newDevice", "hardware_no")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Hardware Serial No."
                    value={state.newDevice.hardware_serial_no}
                    onChange={(e) =>
                      onChange(e, "newDevice", "hardware_serial_no")
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Hardware IMEI"
                    value={state.newDevice.hardware_imei}
                    onChange={(e) => onChange(e, "newDevice", "hardware_imei")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Status"
                    value={state.newDevice.hardware_status}
                    onChange={(e) =>
                      onChange(e, "newDevice", "hardware_status")
                    }
                    select
                  >
                    <MenuItem value="">Select Status</MenuItem>
                    {[
                      ...new Set([
                        "Active",
                        "Inactive",
                        "Lost",
                        state.newDevice.hardware_status,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Hardware Position"
                    value={state.newDevice.hardware_position}
                    onChange={(e) =>
                      onChange(e, "newDevice", "hardware_position")
                    }
                    select
                  >
                    <MenuItem value="">Select Position</MenuItem>
                    {[
                      ...new Set([
                        "With Client",
                        "With Intugine",
                        "Lost",
                        state.newDevice.hardware_position,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Hardware Remarks"
                    value={state.newDevice.hardware_remarks}
                    onChange={(e) =>
                      onChange(e, "newDevice", "hardware_remarks")
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Sim Type"
                    value={state.newDevice.sim_type}
                    onChange={(e) => onChange(e, "newDevice", "sim_type")}
                    select
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    {[
                      ...new Set([
                        "Airtel 10 m2m",
                        "Airtel 13 m2m",
                        "Airtel Prepaid",
                        "Voda 10 m2m",
                        "Voda 13 m2m",
                        "Voda Prepaid",
                        "Idea 13 m2m",
                        "Idea 10 m2m",
                        "Idea Prepaid",
                        state.newDevice.sim_type,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Serial No."
                    value={state.newDevice.sim_serial_no}
                    onChange={(e) => onChange(e, "newDevice", "sim_serial_no")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Mobile No."
                    value={state.newDevice.sim_mobile_no}
                    onChange={(e) => onChange(e, "newDevice", "sim_mobile_no")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Code"
                    value={state.newDevice.sim_code}
                    onChange={(e) => onChange(e, "newDevice", "sim_code")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Sim Status"
                    value={state.newDevice.sim_status}
                    onChange={(e) => onChange(e, "newDevice", "sim_status")}
                    select
                  >
                    <MenuItem value="">Select Status</MenuItem>
                    {[
                      ...new Set([
                        "Active",
                        "Inactive",
                        "Lost",
                        "No Record",
                        state.newDevice.sim_status,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Sim Position"
                    value={state.newDevice.sim_position}
                    onChange={(e) => onChange(e, "newDevice", "sim_position")}
                    select
                  >
                    <MenuItem value="">Select Position</MenuItem>
                    {[
                      ...new Set([
                        "In Device",
                        "With Client",
                        "With Intugine",
                        "Lost",
                        state.newDevice.sim_position,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Remarks"
                    value={state.newDevice.sim_remarks}
                    onChange={(e) => onChange(e, "newDevice", "sim_remarks")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="BT App Password"
                    value={state.newDevice.bt_app_pwd}
                    onChange={(e) => onChange(e, "newDevice", "bt_app_pwd")}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Ownership"
                    value={state.newDevice.device_ownership}
                    onChange={(e) => onChange(e, "newDevice", "device_ownership")}
                    select
                  >
                    <MenuItem value="">Select Ownership</MenuItem>
                    {[
                      ...new Set([
                        "Owned by Client",
                        "Leased",
                        state.newDevice.device_ownership,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() =>
                  onChange(
                    { target: { value: blankDevice } },
                    false,
                    "newDevice"
                  )
                }
                color="primary"
              >
                Clear
              </Button>
              <Button
                onClick={addNewDevice}
                color="primary"
                disabled={!state.newDevice.hardware_no}
              >
                Add new Device
              </Button>
            </DialogActions>
          </Grid>
          <Grid item xs={6}>
            <DialogTitle id="form-dialog-title">Add Sim</DialogTitle>
            <DialogContent>
              <Grid container justify="space-evenly">
                <Grid item xs={8}>
                  <TextField
                    disableEnforceFocus
                    style={{ marginBottom: "10px", width: "201px" }}
                    fullWidth
                    label="Transaction"
                    value={state.newSim.transaction}
                    onChange={(e) => onChange(e, "newSim", "transaction")}
                    select
                  >
                    <MenuItem value="">Select Transaction</MenuItem>
                    {[
                      ...new Set([
                        "Received from Vendor",
                        "Assigned to Device",
                        state.newSim.transaction,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Date"
                    value={state.newSim.date}
                    onChange={(e) => onChange(e, "newSim", "date")}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Added By"
                    value={state.newSim.added_by}
                    onChange={(e) => onChange(e, "newSim", "added_by")}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Vendor"
                    value={state.newSim.vendor}
                    onChange={(e) => onChange(e, "newSim", "vendor")}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Operator"
                    value={state.newSim.operator}
                    onChange={(e) => onChange(e, "newSim", "operator")}
                    select
                  >
                    <MenuItem value="">Select Operator</MenuItem>
                    {[
                      ...new Set([
                        "Airtel",
                        "Vodafone",
                        "Idea",
                        state.newSim.operator,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Sim Type"
                    value={state.newSim.sim_type}
                    onChange={(e) => onChange(e, "newSim", "sim_type")}
                    select
                  >
                    <MenuItem value="">Select Type</MenuItem>
                    {[
                      ...new Set([
                        "Airtel 10 m2m",
                        "Airtel 13 m2m",
                        "Airtel Prepaid",
                        "Voda 10 m2m",
                        "Voda 13 m2m",
                        "Voda Prepaid",
                        "Idea 13 m2m",
                        "Idea 10 m2m",
                        "Idea Prepaid",
                        state.newSim.sim_type,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Mobile No."
                    value={state.newSim.sim_mobile_no}
                    onChange={(e) => onChange(e, "newSim", "sim_mobile_no")}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Serial No."
                    value={state.newSim.sim_serial_no}
                    onChange={(e) => onChange(e, "newSim", "sim_serial_no")}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px" }}
                    label="Sim Code"
                    value={state.newSim.sim_code}
                    onChange={(e) => onChange(e, "newSim", "sim_code")}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Sim Status On Portal"
                    value={state.newSim.sim_status}
                    onChange={(e) => onChange(e, "newSim", "sim_status")}
                    select
                  >
                    <MenuItem value="">Select Status On Portal</MenuItem>
                    {[
                      ...new Set([
                        "Active",
                        "Inactive",
                        "Lost",
                        state.newSim.sim_status,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    style={{ marginBottom: "10px", width: "201px" }}
                    label="Inventory"
                    value={state.newSim.inventory}
                    onChange={(e) => onChange(e, "newSim", "inventory")}
                    select
                  >
                    <MenuItem value="">Select Inventory</MenuItem>
                    {[
                      ...new Set([
                        "On Field",
                        "In Office - Ops",
                        "In Office - Hardware",
                        state.newSim.inventory,
                      ]),
                    ].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={() =>
                  onChange({ target: { value: blankSim } }, false, "newSim")
                }
                color="primary"
              >
                Clear
              </Button>
              <Button onClick={addNewSim} color="primary">
                Add new sim
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
}
